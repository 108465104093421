













import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import PageRequest from '@/store/entities/page-request';
import ListView from '@/components/helper/list-view.vue';
import UserForm from './user-form.vue';

class PageUserRequest extends PageRequest { }

@Component({
    components: { ListView, UserForm }
})
export default class Users extends ListBase {
    entity: string = 'user';
    pagerequest: PageUserRequest = new PageUserRequest();
    
    columns = [
        this.gCol('first_name', 'Nombre', 'text'),
        this.gCol('last_name', 'Apellidos', 'text'),
        this.gCol('username', 'Usuario', 'text'),
        this.gCol('active', 'Habilitado', 'text'),
        this.gColO({ type: 'list', value: 'roles', text: 'Roles' }),
        this.defaultActions()
    ]

    get exluddeIds() {
        let ids = [];
        this.$store.state.user.list.forEach(item => {
            if (item.id == 1)
                ids.push(item.id);
        });

        return { delete: ids };
    }

    edit() {
        this.modalShow = !this.modalShow;
    }
    create() {
        this.modalShow = !this.modalShow;
    }
    async afterSave() {
        let list: any = this.$refs.listView
        await list.getpage();
    }   

    //avatar_type: string;
    //avatar_location: string;
    
    created() {

    }
}